import React from 'react';

import Home from './components/pages/Home';



function App() {
  return (
    <div className="App">
      <Home/>
    </div>
  );
}

export default App;
